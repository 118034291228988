
import { Vue, Component } from 'vue-property-decorator';
import moment from 'moment';
import { addTfse, getTfse, delTfse } from '@/api/tfse';

@Component({
  components: {},
})
export default class extends Vue {
  private count = Number(this.$route.params.cnt);

  private tfseDate: any = this.$route.query.tfseDate ? this.$route.query.tfseDate : moment().format('YYYY-MM-DD');

  private type: any = 'diary';

  private timeAt: any = moment().format('A');

  private hour: any = moment().format('hh');

  private minute: any = this.roundToNearest10Minutes();

  mounted() {
    this.handleTfseDate();
    this.handleTfseForm();
  }

  private roundToNearest10Minutes() {
      const remainder = (moment().minute() % 10) * -1; // 10분 간격으로 조정
      return moment().add(remainder, 'minutes').startOf('minute').format('mm');
  }

  private handleTfseDate() {
    let hour = this.hour;
    if (this.timeAt === '오후' && this.hour < 12) {
      hour = parseInt(this.hour, 10) + 12; // 오후 시간으로 변환
    }
    this.tfse.tfseDate = `${this.tfseDate}T${hour}:${this.minute}:00`;
  }

  private tfse: any = {
    tfseDate: '',
    foodText: '',
    emotionText: '',
    satietyScore: 0,
    secretStatus: true,
  }

  private addTfse() {
    if (this.$route.params.tfseIdx) {
      addTfse(this.tfse).then(() => {
        this.$message.info('정상적으로 수정되었습니다.');
      });
    } else {
      addTfse(this.tfse).then(() => {
        this.$message.info('정상적으로 등록되었습니다.');
      });
    }
    this.$router.push({ name: 'Tfse', query: { tfseDate: this.tfseDate } });
  }

  private delTfse() {
    this.$confirm('정말 삭제하시겠습니까?', 'TFSE일지 삭제', {
      confirmButtonText: '삭제',
      cancelButtonText: '취소',
      type: 'error',
    }).then(() => {
    if (this.$route.params.tfseIdx) {
      delTfse(this.$route.params.tfseIdx).then(() => {
        this.$message.info('정상적으로 삭제되었습니다.');
      });
    }
    this.$router.push({ name: 'Tfse', query: { tfseDate: this.tfseDate } });
    });
  }

  private handleTfseForm() {
    if (this.$route.params.tfseIdx) {
      getTfse(this.$route.params.tfseIdx).then((res) => {
        this.tfse = res.data;
        this.timeAt = moment(this.tfse.tfseDate).format('A');
        this.hour = moment(this.tfse.tfseDate).format('hh');
        const remainder = (moment(this.tfse.tfseDate).minute() % 10) * -1; // 10분 간격으로 조정
        this.minute = moment(this.tfse.tfseDate).add(remainder, 'minutes').startOf('minute').format('mm');
      });
    }
  }

  private handleAddDay(days: number) {
    this.tfseDate = moment(this.tfseDate, 'YYYY-MM-DD')
      .add(days, 'days')
      .format('YYYY-MM-DD');
    this.$router.replace({
      ...this.$router,
      query: { tfseDate: this.tfseDate },
    });
  }
}
